<template>
	<ckeditor
		type="classic"
		class="materialize-textarea"
		v-model="content"
		:editor="editor"
		:config="editorConfig"
	></ckeditor>
</template>
<script>
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import config from '@/config'

class MyUploadAdapter {
	constructor(loader, axios, url, purpose) {
		this.loader = loader
		this.axios = axios
		this.url = url
		this.purpose = purpose
	}
	upload() {
		return this.loader.file.then((file) => {
			return new Promise((resolve, reject) => {
				const data = new FormData()
				data.append('file', file)
				data.append('purpose', this.purpose)
				this.axios({
					url: this.url,
					method: 'post',
					data,
				})
					.then((response) => {
						if (response.success == false) {
							reject({ response })
						} else {
							resolve({ default: response.data.data.publicURL })
						}
					})
					.catch(() => {
						reject('Upload failed')
					})
			})
		})
	}
}

export default {
	name: 'Editor',
	components: {
		ckeditor: CKEditor.component,
	},
	props: {
		initData: {
			type: String,
			default: '',
		},
		url: {
			type: String,
			default: config.api.baseURL + '/media/',
		},
		purpose: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			editor: ClassicEditor,
			content: '',
			editorConfig: {
				axios: this.axios,
				url: this.url,
				purpose: this.purpose,
				extraPlugins: [this.customUploadAdapterPlugin],
				heading: {
					options: [
						{
							model: 'paragraph',
							title: 'Paragraph',
							class: 'ck-heading_paragraph',
						},
						{
							model: 'heading1',
							view: 'h1',
							title: 'Heading 1',
							class: 'ck-heading_heading1',
						},
						{
							model: 'heading2',
							view: 'h2',
							title: 'Heading 2',
							class: 'ck-heading_heading2',
						},
						{
							model: 'heading3',
							view: 'h3',
							title: 'Heading 3',
							class: 'ck-heading_heading3',
						},
						{
							model: 'heading4',
							view: 'h4',
							title: 'Heading 4',
							class: 'ck-heading_heading4',
						},
						{
							model: 'heading5',
							view: 'h5',
							title: 'Heading 5',
							class: 'ck-heading_heading5',
						},
						{
							model: 'heading6',
							view: 'h6',
							title: 'Heading 6',
							class: 'ck-heading_heading',
						},
					],
				},
				mediaEmbed: { previewsInData: true },
			},
		}
	},
	created() {
		if (this.initData) {
			this.content = this.initData
		}
	},
	watch: {
		content: function (val) {
			this.$emit('changed', val)
		},
		initData() {
			this.content = this.initData
		}
	},
	methods: {
		customUploadAdapterPlugin: function (editor) {
			const config = editor.config._config
			editor.plugins.get('FileRepository').createUploadAdapter = (
				loader
			) => {
				return new MyUploadAdapter(
					loader,
					config.axios,
					config.url,
					config.purpose
				)
			}
		},
	},
}
</script>

<style>
/* CK EDITOR */
#ckcontent p:first-child {
	margin-top: 0px;
}
#ckcontent blockquote {
	margin: 20px 0;
	padding-left: 20px;
	border-left: 4px solid var(--green);
	font-size: 1.25rem;
	font-weight: 200;
}
#ckcontent p {
	margin-top: 0px !important;
	font-size: 1.1rem;
	line-height: 1.6;
	text-align: left;
	margin-bottom: 0px !important;
}

#ckcontent h1,
#ckcontent h2,
#ckcontent h3,
#ckcontent h4,
#ckcontent h5,
#ckcontent h6 {
	font-size: 2rem;
	font-weight: 500;
	text-align: left;
	line-height: 1.3;
	margin-bottom: 30px !important;
	margin-top: 0px;
}

#ckcontent a {
	color: var(--green) !important;
}
.ck-dropdown__panel .ck-list {
	margin-top: 0px !important;
	padding-left: 0px !important;
}
.ck-dropdown__panel .ck-list .ck-list__item {
	padding-left: 0px !important;
}
.ck-editor__editable {
	padding: 10px 5px !important;
}
#ckcontent ol,
#ckcontent ul {
	margin-top: 20px;
	font-size: 1.1rem;
	line-height: 1.6;
	text-align: left;
	padding-left: 20px;
}

#ckcontent ol li,
#ckcontent ul li {
	padding-left: 5px;
	margin-bottom: 0px !important;
}
#ckcontent ol li h1,
#ckcontent ul li h1 {
	font-size: var(--heading-size);
	font-weight: 500;
	padding-left: 5px;
	margin-bottom: 0px !important;
}
#ckcontent ul li {
	list-style-type: disc;
}
/* SECTION IMAGE STARTS */
#ckcontent img {
	margin-top: 20px;
	max-width: 60%;
	max-height: 400px;
}
</style>
